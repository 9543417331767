import SvgEditor from './index';
import {EDITOR_SPACING, CELL_MAX_WIDTH} from '../config';

export default class RingPanel extends SvgEditor {
    constructor (config, faceData) {
        super(config, faceData);
        const {
            size: {width = CELL_MAX_WIDTH},
            count = 128,
            interval = 0
        } = config;
        this.r = (width || CELL_MAX_WIDTH) / 2;
        this.R = (this.r + interval) / Math.sin(this.angle / 2);
        this.x0 = this.r;
        this.size = {
            width: 30,
            height: 30
        };
        this.row = 16;
        this.cloumn = 8;
    }

    generator () {
        const {
            size: {width = CELL_MAX_WIDTH},
            count = 128,
            offsetCount = 1,
            offset = CELL_MAX_WIDTH,
            boxspacing = EDITOR_SPACING
        } = this.config;
        const {size, row, cloumn, faceData} = this;
        // for (let index = 0; index < cloumn; index++) {
        //     for (let i = 0; i < row; i++) {
        //         const x = (34 * i) + 3;
        //         const y = (34 * index) + 3;
        //         const colorIndex = faceData[(8 * i) + index] || 0;
        //         const attrs = {
        //             width: size.width,
        //             height: size.height,
        //             x: x,
        //             y: y,
        //             rx: 8,
        //             ry: 8,
        //             dataindex: (8 * i) + index
        //         };
        //         this.addCellElement(attrs, colorIndex, 'rect');
        //         if (index === 0) {
        //             const xs = x + 8;
        //             this.addAxisPoint(
        //                 i - 1,
        //                 0,
        //                 boxspacing + xs
        //             );
        //         }
        //     }
        //     const xs = 0;
        //     const ys = (34 * index) + 3;
        //     this.addAxisPoint(
        //         index - 1,
        //         boxspacing + ys + 8,
        //         xs
        //
        //     );
        // }
        for (let index = 0; index < cloumn; index++) {
            for (let i = 0; i < row; i++) {
                const x = (34 * i) + 3;
                const y = (34 * index) + 3;
                const colorIndex = faceData[(16 * index) + i] || 0;
                const attrs = {
                    width: size.width,
                    height: size.height,
                    x: x,
                    y: y,
                    rx: 8,
                    ry: 8,
                    dataindex: (16 * index) + i
                };
                this.addCellElement(attrs, colorIndex, 'rect');
                if (index === 0) {
                    const xs = x + 8;
                    this.addAxisPoint(
                        i - 1,
                        0,
                        boxspacing + xs
                    );
                }
            }
            const xs = 0;
            const ys = (34 * index) + 3;
            this.addAxisPoint(
                index - 1,
                boxspacing + ys + 8,
                xs

            );
        }
        this.boxWidth = 546;
        this.boxHeight = 274;
    }
}
