import {formatMessage, templateHTML} from './sources';
import {colorItemTemplate, EDITOR_SPACING, MAP_WHITE_COLOR_BORDER, TRANSPARENT_MAP_COLORS} from './config';
import FaceCanvas from './led-editor/canvas';
import {faceDataToCustomImages, faceDataToImages} from './util.js';

export default class LEDEditor {
    constructor (config) {
        this._config = config;
        this._container = null;
        this._field = '';
        this._canvas = new FaceCanvas(this._config);
        this._customImages = JSON.parse(localStorage.getItem('FACE_DATA') || '[]');
        this.render();
    }

    get actions () {
        return {
            // CHANGE_COLOR: (index, ele) => {
            //     if (ele instanceof HTMLElement) {
            //         index = Number(index);
            //         if (this._container) {
            //             if (!ele.classList.contains('color-item-selected')) {
            //                 this.switchColorByIndex(index);
            //             }
            //             this.switchToEditMode();
            //         }
            //     }
            // },
            DRAW: (index, ele) => {
                if (ele instanceof HTMLElement) {
                    if (this._container) {
                        this.switchToEditMode();
                    }
                }
            },
            ERASE: (index, ele) => {
                if (ele instanceof HTMLElement) {
                    if (this._container) {
                        this.switchToEraseMode();
                    }
                }
            },
            CLEAN: () => {
                if (this._container) {
                    this.switchToCleanMode();
                }
            },
            RULER: () => {
                if (this._container) {
                    this.switchToRulerMode();
                }
            },
            PREVIEW: (data, ele) => {
                if (this._container) {
                    this.switchToPreviewMode(data, ele);
                }
            },
            REMOVE_CUSTOM_FACE: index => {
                if (this._container) {
                    this.removeCustomFace(index);
                }
            },
            PREV_FACE: () => {
                if (this._container) {
                    this.switchToPrevFace();
                }
            },
            NEXT_FACE: () => {
                if (this._container) {
                    this.switchToNextFace();
                }
            },
            PLAY: () => {
                if (this._container) {
                    this.switchToPlayMode();
                }
            },
            SAVE: () => {
                if (this._container) {
                    this.switchToSaveMode();
                }
            },
            CANCEL: () => {
                this.close();
            },
            OK: () => {
                const field = this._field;
                const facedata = this.canvas.faceData;
                // console.log(facedata);
                if (field && field.setValue) {
                    const setValue = field.setValue;
                    if (typeof setValue === 'function') {
                        setValue.call(field, facedata.toString());
                    }
                }
                this.close();
            }
        };
    }

    render () {
        const {
            guid = 'default',
            showNumber = true,
            colorIndex = 1,
            boxspacing = EDITOR_SPACING,
            multiColor = false,
            bgimg
        } = this._config;
        const id = `face-panel_${guid.replace(/-/g, '')}`;
        let container = document.querySelector(`#${id}`);
        if (!(container instanceof Element)) {
            container = document.createElement('section');
            container.setAttribute('id', id);
            container.setAttribute('class', 'face-panel face-panel-hide');
            container.innerHTML = templateHTML;

            this._axisContainer = container.querySelector(
                '.fp-axis'
            );
            this._svgContainer = container.querySelector(
                '.fp-svg-canvas'
            );
            this._colorsContainer = container.querySelector(
                '.color-list'
            );
            this._okBtn = container.querySelector(
                'button[data-action="OK"]'
            );
            this._cancelBtn = container.querySelector(
                'button[data-action="CANCEL"]'
            );

            // fp-actions
            this._actionsContainer = container.querySelector(
                '.fp-actions'
            );

            // fp-slides-list
            this._slidesListContainer = container.querySelector(
                '.fp-preset-list'
            );

            // fp-slides-content
            this._slidesContentContainer = container.querySelector(
                '.fp-slides-content'
            );

            if (this._actionsContainer instanceof Element) {
                this._actionsContainer.innerHTML = `
                    <a class="action-item" data-action="DRAW">
                      <input id="action_DRAW"
                          class="action-radio" type="radio" name="action_edit" checked>
                          <label for="action_DRAW" class="fp-btn-action">
                            <svg class="fp-icon">
                              <use xlink:href="#iconedit"></use>
                          </label>
                    </a>
                    <a class="action-item" data-action="ERASE">
                      <input id="action_ERASE"
                          class="action-radio" type="radio" name="action_edit">
                          <label for="action_ERASE" class="fp-btn-action">
                            <svg class="fp-icon">
                              <use xlink:href="#iconeraser"></use>
                          </label>
                    </a>
                    <a class="action-item" data-action="CLEAN">
                      <label for="action_CLEAN" class="fp-btn-action">
                        <svg class="fp-icon">
                          <use xlink:href="#icondelete"></use>
                      </label>
                    </a>
                    <a class="action-item" data-action="RULER">
                      <label for="action_RULER" class="fp-btn-action">
                        <svg class="fp-icon">
                          <use xlink:href="#icon-btn_axis"></use>
                        </svg>
                      </label>
                    </a>
                    <a class="action-item" data-action="SAVE">
                      <label for="action_SAVE" class="fp-btn-action">
                        <svg class="fp-icon">
                          <use xlink:href="#icon-a-zu3156"></use>
                      </label>
                    </a>
                `;
            }
            if (this._colorsContainer instanceof HTMLElement) {
                if (multiColor === true) {
                    this._colorsContainer.innerHTML = this.genColorsContent()
                        .join(
                            ''
                        );
                } else {
                    this._colorsContainer.remove();
                }
            }
            if (this._slidesListContainer instanceof Element) {
                this._slidesListContainer.innerHTML = this.genSlidesListContent()
                    .join(
                        ''
                    );
            }

            if (this._svgContainer instanceof HTMLElement) {
                this._svgContainer.appendChild(this.canvas.canvasElement);
                if (bgimg) {
                    const url = typeof bgimg === 'string' ? bgimg : bgimg.url;
                    if (url) {
                        this._svgContainer.style.backgroundImage = `url(${url})`;
                    }
                }
                this._svgContainer.style.padding = `${boxspacing}px`;
            }

            if (this._axisContainer instanceof Element) {
                this._axisContainer.innerHTML = this.canvas.panel.axisElements.join(
                    ''
                );
                if (!showNumber) {
                    this._axisContainer.classList.add('fp-axis-hide');
                }
            }

            this.canvas.colorIndex = colorIndex;

            this.addEventListener(container);

            document.body.appendChild(container);
        }
        this._container = container;
    }

    addEventListener (container) {
        if (container) {
            container.addEventListener(
                'click',
                ev => {
                    const actionsHandler = this.actions;
                    let ele = ev.srcElement;
                    if (actionsHandler) {
                        while (ele instanceof Element) {
                            const action =
                                ele.getAttribute('data-action') || '';
                            if (action !== '') {
                                if (
                                    actionsHandler[action] &&
                                    typeof actionsHandler[action] === 'function'
                                ) {
                                    const actionData = ele.getAttribute(
                                        'data-action-value'
                                    );
                                    actionsHandler[action](
                                        actionData || '',
                                        ele
                                    );
                                }
                                break;
                            }
                            ele = ele.parentElement;
                        }
                    }
                },
                false
            );
        }
    }

    get canvas () {
        return this._canvas;
    }

    preview (data) {
        const faceData = this.canvas.faceData;
        if (!data) {
            data = this.canvas.panel.emptyFaceData.join('');
        }
        if (data && data !== faceData) {
            this.canvas.faceData = data;
        }
    }

    open (data, field) {
        this._field = field;
        this.preview(data);
        this.setButtonText();
        setTimeout(() => {
            if (this._container instanceof HTMLElement) {
                this._container.style.visibility = 'visible';
                this._container.classList.remove('face-panel-hide');
                this._container.classList.add('face-panel-show');
            }
        }, 0);
    }

    close () {
        if (this._field) {
            this._field = null;
        }
        if (this._container instanceof HTMLElement) {
            this._container.style.visibility = 'hidden';
            this._container.classList.remove('face-panel-show');
            this._container.classList.add('face-panel-hide');
        }
        this.switchColorByIndex(1);
    }

    genColorsContent () {
        const {colors, colorIndex = 1} = this._config;
        return colors.map((color, index) => {
            const bgColor = color;
            const isWhite =
                TRANSPARENT_MAP_COLORS.indexOf(color.toUpperCase()) >= 0;
            const boxShadow = '';
            if (isWhite) {
                color = MAP_WHITE_COLOR_BORDER;
            } else {
                color = '#fff';
            }
            return colorItemTemplate(
                color,
                bgColor,
                boxShadow,
                index,
                index === colorIndex
            );
        });
    }

    switchToEditMode () {
        this.forceStopPlay();
        if (this._container instanceof HTMLElement) {
            const radio = this._container.querySelector(
                '[data-action="DRAW"] > input[type="radio"]'
            );
            if (radio instanceof HTMLInputElement) {
                this.canvas.mode = 'DRAW';
                radio.click();
            }
        }
    }

    switchToEraseMode () {
        this.forceStopPlay();
        if (this._container instanceof HTMLElement) {
            const radio = this._container.querySelector(
                '[data-action="ERASE"] > input[type="radio"]'
            );
            if (radio instanceof HTMLInputElement) {
                this.canvas.mode = 'ERASE';
                radio.click();
            }
        }
    }

    switchToCleanMode () {
        this.forceStopPlay();
        if (this._container instanceof HTMLElement) {
            const field = this._field;
            const faceData = this.canvas.panel.emptyFaceData.join('');
            if (field && field.setValue) {
                const setValue = field.setValue;
                if (typeof setValue === 'function') {
                    setValue.call(field, faceData.toString());
                }
            }
            this.canvas.faceData = faceData;
        }
    }

    switchToRulerMode () {
        if (this._container instanceof HTMLElement) {
            this.showNumber = !this.showNumber;
            if (this.showNumber) {
                this._axisContainer.classList.add('fp-axis-hide');
            } else {
                this._axisContainer.classList.remove('fp-axis-hide');
            }
        }
    }

    switchToPreviewMode (data, ele) {
        this.preview(data);
        if (ele instanceof HTMLElement) {
            const {offsetLeft, offsetWidth} = ele;
            const {clientWidth, scrollWidth, scrollLeft} = this._slidesContentContainer;
            const offset = offsetLeft + (offsetWidth / 2);
            const scroll = scrollLeft + (clientWidth / 2);
            const left = offset - scroll;
            const right = scroll - offset;
            if (left > 0) {
                this._slidesContentContainer.scrollLeft += left;
            } else if (right > 0 && this._slidesContentContainer.scrollLeft !== 0) {
                this._slidesContentContainer.scrollLeft -= right;
            }
        }
    }

    switchToPrevFace () {
        if (this._slidesContentContainer.scrollLeft === 0) {
            // skip
            // this._slidesContentContainer.scrollLeft = this._slidesContentContainer.scrollWidth;
        } else {
            // this._slidesContentContainer.scrollLeft -= this._slidesContentContainer.clientWidth;
            this._slidesContentContainer.scrollLeft -= 140;
        }
    }

    switchToNextFace () {
        // console.log('switchToNextFace', this._slidesContentContainer.clientWidth, this._slidesContentContainer.scrollWidth, this._slidesContentContainer.scrollLeft);
        if (this._slidesContentContainer.scrollLeft === this._slidesContentContainer.scrollWidth) {
            this._slidesContentContainer.scrollLeft = 0;
        } else {
            this._slidesContentContainer.scrollLeft += 140;
        }
    }

    switchToPlayMode () {
        // 切换xlink:href
        if (this._container instanceof HTMLElement) {
            const play = this._container.querySelector('[data-action="PLAY"]');
            if (play instanceof HTMLElement) {
                const {href} = play.querySelector('use');
                // console.log('play', href);
                if (href instanceof SVGAnimatedString) {
                    const {baseVal} = href;
                    //     const newHref = baseVal.replace('-play', 'stop');
                    //     href.baseVal = newHref;
                    // }
                    // 判断是否是播放状态
                    if (baseVal.indexOf('play') === -1) {
                        play.querySelector('use')
                            .setAttribute('xlink:href', '#icon-play');
                        this.stopAutoPlay();
                    } else {
                        play.querySelector('use')
                            .setAttribute('xlink:href', '#iconstop');
                        this.autoPlay();
                    }
                }
            }
        }
    }

    forceStopPlay () {
        if (this._container instanceof HTMLElement) {
            const play = this._container.querySelector('[data-action="PLAY"]');
            if (play instanceof HTMLElement) {
                const {href} = play.querySelector('use');
                // console.log('play', href);
                if (href instanceof SVGAnimatedString) {
                    play.querySelector('use')
                        .setAttribute('xlink:href', '#icon-play');
                    this.stopAutoPlay();
                }
            }
        }
    }


    autoPlay () {
        // const items = this._slidesListContainer.querySelectorAll('.fp-preset-item');
        // console.log('items', items);
        // 遍历所有的item
        if (this._slidesListContainer instanceof HTMLElement) {
            const items = this._slidesListContainer.querySelectorAll('.fp-preset-item');
            if (items instanceof NodeList) {
                // 索引
                let _currentIndex = 0;
                this._autoPlayTimer = setInterval(() => {
                    // 切换
                    if (_currentIndex > 0) {
                        items[_currentIndex - 1].classList.remove('fp-preset-highlight');
                    } else {
                        items[items.length - 1].classList.remove('fp-preset-highlight');
                    }
                    items[_currentIndex].click();
                    items[_currentIndex].classList.add('fp-preset-highlight');
                    // 判断是否是最后一个
                    if (_currentIndex === items.length - 1) {
                        _currentIndex = 0;
                    } else {
                        _currentIndex += 1;
                    }
                }, 500);
            }
        }
    }

    stopAutoPlay () {
        clearInterval(this._autoPlayTimer);
    }

    switchToSaveMode () {
        this.forceStopPlay();
        const faceData = this.canvas.faceData;
        // 放入数组
        this._customImages.push(faceData);
        localStorage.setItem('FACE_DATA', JSON.stringify(this._customImages));
        if (this._slidesListContainer instanceof Element) {
            this._slidesListContainer.innerHTML = this.genSlidesListContent()
                .join(
                    ''
                );
        }
        // 延迟刷新
        setTimeout(() => {
            this._slidesContentContainer.scrollLeft = this._slidesContentContainer.scrollWidth;
        }, 200);
    }

    removeCustomFace (index) {
        if (index >= 0 && index < this._customImages.length) {
            this._customImages.splice(index, 1);
            if (this._slidesListContainer instanceof Element) {
                this._slidesListContainer.innerHTML = this.genSlidesListContent()
                    .join(
                        ''
                    );
            }
            localStorage.setItem('FACE_DATA', JSON.stringify(this._customImages));
        }
    }

    switchColorByIndex (index) {
        if (this._container) {
            const {multiColor} = this._config;
            if (multiColor) {
                this.canvas.colorIndex = index;
                this._container
                    .querySelectorAll('[data-action=CHANGE_COLOR]')
                    .forEach(ele => {
                        const idx = ele.getAttribute('data-action-value');
                        if (Number(idx) === index) {
                            ele.classList.add('color-item-selected');
                        } else {
                            ele.classList.remove('color-item-selected');
                        }
                    });
            }
        }
    }

    setButtonText () {
        if (this._okBtn instanceof HTMLElement) {
            const text = formatMessage('gui.modal.ok');
            if (this._okBtn.innerText !== text && text) {
                this._okBtn.innerText = text;
            }
        }
        if (this._cancelBtn instanceof HTMLElement) {
            const text = formatMessage('gui.modal.cancel');
            if (this._cancelBtn.innerText !== text && text) {
                this._cancelBtn.innerText = text;
            }
        }
    }

    genSlidesListContent () {
        // console.log(faceDataToImages1);
        const faceDataToImages1 = faceDataToImages(this._config,
            ['00000000000000000000000000000000000111000011100000111110011111000010001001000100000000000000000000000000000000000000000000000000',
                '00000000000000000011100000011100011111000011111001110100001110100111010000111010010001000010001000111000000111000000000000000000',
                '00000000000000000011100000011100000000000000000000111000000111000111010000111010011101000011101001000100001000100011100000011100',
                '00000000000000000000000000011100000000000011011000011100001001100000011000111110000111000011111000000000000111000000000000000000',
                '00000000000000000011100000011100000001000010000000011000000110000011110000111100001111000011110000111100001111000000000000000000']);
        const faceDataToImages2 = faceDataToCustomImages(this._config,
            this._customImages);
        faceDataToImages1.push(...faceDataToImages2);
        return faceDataToImages1;
    }
}
