/* eslint-disable no-console */
/* eslint-disable no-mixed-operators */

import Blockly from 'scratch-blocks';
import LEDEditor from './field-face-led/index.js';
import {binaryToHex, faceDataToSvg, hexToBinary} from './field-face-led/util';

const config = {
    actions: [],
    bgimg: {name: 'matatacar.png', url: ''},
    boxspacing: 20,
    colorIndex: 1,
    colors: ['#ffffff', '#FF931E', '#ffA500', '#ffff00', '#00ff00', '#00ffff', '#0000ff', '#8000ff', '#000000'],
    columns: 16,
    count: 128,
    create_time: 1653898900,
    defaultFace: '',
    defaultFaces: ['0'],
    defaultValue: '000000001c383e7c2244000000000000',
    enableAnimation: false,
    ftype: 0,
    guid: '9d03c0b1',
    id: '1',
    interval: 30,
    modify_time: 1653898900,
    multiColor: false,
    name: 'ledRing',
    offsetCount: 1,
    radius: 12,
    rows: 8,
    showNumber: true,
    size: {width: 16, height: 16},
    sort: 999,
    startNumber: 1,
    type: 'field_panel-editor',
    uid: 1111
};

const cacheFacePanel = {};

class FieldLedPanelEditor extends Blockly.Field {
    constructor (options) {
        super();
        this.options = options;
    }

    willInit () {
        this.setValue(this.getValue());
    }

    getValue () {
        const {
            defaultValue,
            ftype = 0,
            count = 10,
            rows = 8,
            colums = 16
        } = this.options;
        // console.log(binaryToHex(this.data_));
        return (
            this.data_ ||
            defaultValue ||
            new Array(ftype === 0 ? rows * colums : count).fill(0)
                .join('')
        );
    }

    setValue (data) {
        try {
            if (!data || this.data_ === data) {
                return;
            }
            if (
                Blockly.Events.isEnabled() &&
                this.sourceBlock_ &&
                this.data_ !== data
            ) {
                Blockly.Events.fire(
                    new Blockly.Events.BlockChange(
                        this.sourceBlock_,
                        'field',
                        this.name,
                        binaryToHex(this.data_),
                        data
                    )
                );
            }
            if (data.length === 128) {
                this.data_ = data;
            } else {
                this.data_ = hexToBinary(data);
            }
            this.render_();
        } catch (error) {
            console.warn(error);
        }
    }

    render_ () {
        if (this.fieldGroup_ && this.options) {
            const svgElement = faceDataToSvg(
                this.options,
                this.data_,
                32,
                true
            );
            if (svgElement instanceof SVGElement) {
                const width = Number(svgElement.getAttribute('width'));
                const height = Number(svgElement.getAttribute('height'));
                const xml = svgElement.outerHTML;
                this.size_.width = width;
                this.size_.height = height;
                if (this.fieldGroup_.innerHTML !== xml) {
                    this.fieldGroup_.innerHTML = xml;
                }
                if (this.sourceBlock_ && this.sourceBlock_.rendered) {
                    this.sourceBlock_.render();
                }
            }
        }
    }

    showEditor_ () {
        if (this.options) {
            const data = this.getValue();
            const {guid} = this.options;
            let facepanel;
            if (cacheFacePanel.hasOwnProperty(guid)) {
                facepanel = cacheFacePanel[guid];
                // console.log({cache: facepanel});
            } else {
                facepanel = cacheFacePanel[guid] = new LEDEditor(
                    this.options,
                    data
                );
            }
            facepanel.open(data, this);
        }
    }
}

FieldLedPanelEditor.fromJson = function (options) {
    options = config;
    return new FieldLedPanelEditor(options);
};


Blockly.Field.register('field_face_led', FieldLedPanelEditor);

Blockly.Blocks.face_led = {
    /**
     * Block for matrix value.
     * @this Blockly.Block
     */
    init: function () {
        this.jsonInit({
            message0: '%1',
            args0: [
                {
                    type: 'field_face_led',
                    name: 'FACE_LED'
                }
            ],
            outputShape: Blockly.OUTPUT_SHAPE_ROUND,
            output: 'Number',
            extensions: ['colours_pen']
        });
    }
};
